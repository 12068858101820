import Roadmap from "./Roadmap";


  
  export default function Mern() {
   
      
      return (
        <div>
          <Roadmap/>
        
        </div>
      );
  }
  